import React from "react"
import { Link } from "gatsby"


// Components
import PageError from "../components/PageError"


interface IPageError404PageDefaultProps {
    location: any,
}

const PageError404Page = (props: IPageError404PageDefaultProps) => {
    const pageId = "pageError"
    const pageTitle = "404: Not found"
    const errorNumber = "404"
    const location = props.location
    const crumbLabel =  "お探しのページが見つかりません"
    const text1 = "お探しのページが見つかりません"
    const text2 = "ご指定のURLに誤りがあるか、お探しのページが削除された可能性があります"

    return (
        <PageError
            pageId={pageId}
            pageTitle={pageTitle}
            crumbLabel={crumbLabel}
            location={location}
            errorNumber={errorNumber}
            text1={text1}
            text2={text2}
        >
            <div style={{ textAlign: "center" }}>
                <Link to="/" className="button">ホームへ移動する</Link>
            </div>
        </PageError>
    )
}

export default PageError404Page
